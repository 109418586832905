
.wrap{
    flex: 1;
    max-width: 1024px;
    margin: 0px auto;
    .brief{
       text-align: center;
       h2{
         padding: 12px 0px;
         font-size: 25px;
       }
       img{
          width: 150px;
          height: 150px;
          border-radius: 120px;
          margin-top: 20px;
          // transform: translateZ(0);
          // transition: opacity .25s linear;
          // will-change: opacity;
       }
       ul{
        display: flex;
        justify-content: center;
        list-style-type:none;
         li{
            padding: 2px 5px;
            background: rgba(87, 199, 255, 0.1);
            margin: 12px 5px;
            // border-radius: 3px;
            border-left: 3px solid rgba(87, 199, 255, 0.3);
            border-right: 3px solid rgba(87, 199, 255, 0.3);
         }      
       }
       p{
        padding: 12px 0px;
        color: rgba(0, 0, 0, 0.7);
      }
    }

    .work{
        text-align: center;
        padding-top: 40px;
        padding-bottom: 25px;
        .title{
          padding-bottom: 10px;
          h3{
            font-size: 30px;
            margin-bottom: 10px;
            color: rgba(0,0,0,.8);
            font-weight: 400;
          }
          p{
            color: rgba(0, 0, 0, 0.7);
          }
        }
    }
}