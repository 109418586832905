
.wrap{
    flex: 1;
    .box{
        max-width: 860px;
        margin: 0px auto;
        padding: 40px 0px;
        .title{
            h1{
                font-size: 24px;
            }
            p{
                color: #999;
                padding-top: 8px;
                font-size: 13px;
            }
        }
        .content{
            display: flex;
            .contentleft{
                width: 70%;
                padding-right: 20px;
                border-right: 2px solid rgb(242, 242, 242);    
            }
            .contentright{
                width: 30%;
                padding-left: 20px;
            }
        }
    }
}