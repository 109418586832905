
.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  .content{
     flex: 1;
  }
  .header{
    background-color: rgb(250, 250, 250);
    height: 60px;
    .selected{
      color: #333!important;
      padding-bottom: 5px;
      border-bottom: 1.5px solid #333;
    }
    .box{
      // width: 70%;
      max-width: 1024px;
      height: 100%;
      margin: 0px auto;
      padding: 0px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a:hover { 
        text-decoration:none; 
      }
      .boxleft{
        font-size: 20px;
        color: rgb(51, 51, 51);
      }
      .boxleft::after {
        content: "◦◦◦";
        margin-left: 0.5em;
      }
      .boxright{
        display: inline-flex;
        justify-content: flex-start;
        p{
          margin: 0px 10px;
          font-size: 14px;
          color: rgb(102, 102, 102);
        }
      }
      div{
        cursor: pointer;
      }
    }
  }
  .footer{
    text-align: center;
    padding: 50px 0px;
    color: #555;
    background: #fafafa;
    border-top: 1px solid #f2f2f2;
    p{
      padding: 2px 0px;
    }
  }
}

